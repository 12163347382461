import React from 'react';

export const RequestSentDial: React.FC = () => (
  <div className="lottie-wrapper">
    <div className="dial-container">
      <img
        src="/images/dial-filled-text.png"
        sizes="(max-width: 479px) 90vw, (max-width: 670px) 100vw, 670px"
        alt=""
        className="request-pending image-3"
      />
    </div>
    <div className="content-bottom" style={{ padding: '10% 5%' }}>
      <img src="/images/icon-alert.png" alt="" className="icon-bottom" />
      <div className="p text-dark text-bold">
        Do not close this page or you&#x27;ll have to send your request again.
      </div>
    </div>
  </div>
);

export default RequestSentDial;
